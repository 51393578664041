import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import  {PorfolioSlider,PortfolioSection,PorfolioSliderCol,
    PortfolioInnerContainer,PrevImg,NextImg,NextPrev,ImgButton,SliderHoverTitle,
    SliderHover,SliderHoverText} from './portfolio.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";


class Portfolio extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            className: "center-portfolio",
            centerMode: true,
            infinite: true,
            arrows:false,
            centerPadding:"0px",
            slidesToShow: 1,
            speed: 1000,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                }
            ]
        };

        return(
                <PortfolioSection id="portfolioContainer">
                    <Container>
                        <Row>
                            <Col md="12">
                                <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                                    <SectionHeading>{this.props.corporateJson.Portfolio.SectionHeading}</SectionHeading>
                                </Fade>
                            </Col>
                        </Row>

                    </Container>
                    <Container>
                        <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                            <PorfolioSlider>
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    this.props.corporateJson.Portfolio.PorfolioSlider.map((item,idx) => {
                                    return <PorfolioSliderCol>
                                        <PortfolioInnerContainer>
                                            <GatsImg 
                                                fluid={item.PorfolioSliderImg.childImageSharp.fluid} 
                                                className="PorfolioSliderImg"
                                                alt=""
                                            />
                                            <SliderHover>
                                                <SliderHoverTitle>
                                                {item.PorfolioSliderTitle}
                                                </SliderHoverTitle>
                                                <a href={item.PorfolioSliderLink} target="_blank" style={{color: '#ff5900', fontSize: 20}}>{item.PorfolioSliderLink}</a>
                                                <SliderHoverText>
                                                {item.PorfolioSliderHoverText}
                                                </SliderHoverText>
                                            </SliderHover>
                                        </PortfolioInnerContainer>
                                    </PorfolioSliderCol>
                                    })
                                }
                            </Slider>
                            </PorfolioSlider>
                        </Fade>
                        <Fade bottom delay={this.props.corporateJson.Portfolio.DelayConstant}>
                            {/*
                            <NextPrev>
                                <ImgButton onClick={this.previous} aria-label="Prev Button">
                                    <PrevImg src={this.props.corporateJson.Portfolio.PrevImg} alt=""/>
                                </ImgButton>
                                <ImgButton onClick={this.next} aria-label="Next Button">
                                    <NextImg src={this.props.corporateJson.Portfolio.NextImg} alt=""/>
                                </ImgButton>
                            </NextPrev>
                            */}
                        </Fade>
                    </Container>
                </PortfolioSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                    corporateJson{
                        Portfolio{
                            DelayConstant
                            SectionHeading
                            SectionSubHeading
                            PorfolioSlider{
                                PorfolioSliderHoverText
                                PorfolioSliderTitle
                                PorfolioSliderLink
                                PorfolioSliderImg{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Portfolio corporateJson={data.corporateJson}/>
        )}
    />
  )