import React from 'react';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import {KeyServicesSection,
    KeyServicesCol,
    KeyServicesIcon,
    KeyServicesHeading,
    KeyServicesCustomContainer,
    KeyServicesDesc} from './keyservices.style'
import {SectionHeading} from '../Common/common.style';
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const KeyServices = () => {
    const JSONData  = useStaticQuery(graphql`
    query{
        corporateJson {
            KeyServices {
                DelayConstant
                SectionHeading
                SectionSubHeading
                KeyServicesCustomContainer{
                    KeyServicesIcon
                    KeyServicesHeading
                    KeyServicesDesc
                }
            }
        }
    }
`);
        return (
        <KeyServicesSection id="keyServicesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={JSONData.corporateJson.KeyServices.DelayConstant}>
                            <SectionHeading>{JSONData.corporateJson.KeyServices.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <KeyServicesCustomContainer>
                    <Row>
                    {
                        JSONData.corporateJson.KeyServices.KeyServicesCustomContainer.map((item,idx) => {
                        return <Col md="6" sm="6" lg="4">
                            <Fade bottom delay={(idx+1)*JSONData.corporateJson.KeyServices.DelayConstant}>
                                <KeyServicesCol>
                                    <KeyServicesIcon src={item.KeyServicesIcon} alt=""/>
                                    <KeyServicesHeading>{item.KeyServicesHeading}</KeyServicesHeading>
                                    <KeyServicesDesc>{item.KeyServicesDesc}</KeyServicesDesc>
                                </KeyServicesCol>
                            </Fade>
                        </Col>
                        })
                    }
                    </Row>
                </KeyServicesCustomContainer>
            </Container>
        </KeyServicesSection>
    );
}

export default KeyServices;
