/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

    const metaDescription = description
//   const metaDescription = description || site.siteMetadata.description

    const HTML = `{
\t"@context": "https://schema.org",
\t"@type": "LocalBusiness",
\t"name": "Bitten Byte, Inc.",
\t"address": {
\t\t"@type": "PostalAddress",
\t\t"streetAddress": "99 Wall St Ste 340",
\t\t"addressLocality": "New York",
\t\t"addressRegion": "NY",
\t\t"postalCode": "10005"
\t},
\t"image": "https://bittenbyte.com/corporate-images/bittenbyte_logo_color.png",
\t"email": "hello@bittenbyte.com",
\t"telePhone": "+19142509487",
\t"url": "https://bittenbyte.com",
\t"paymentAccepted": [ "credit card" ],
\t"openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 0-24",
\t"openingHoursSpecification": [ {
\t\t"@type": "OpeningHoursSpecification",
\t\t"dayOfWeek": [
\t\t\t"Monday",
\t\t\t"Tuesday",
\t\t\t"Wednesday",
\t\t\t"Thursday",
\t\t\t"Friday"
\t\t],
\t\t"opens": "09:00",
\t\t"closes": "17:00"
\t} ],
\t"geo": {
\t\t"@type": "GeoCoordinates",
\t\t"latitude": "40.704806",
\t\t"longitude": "-74.007281"
\t},
\t"priceRange":"$$$"
}`;

    return (
        <>
            <script type="application/ld+json" dangerouslySetInnerHTML={{__html: HTML}}>

            </script>
        <Helmet
        htmlAttributes={{
            lang,
        }}
        title={title}
        titleTemplate={`%s`}
        meta={[
            {
            name: `description`,
            content: metaDescription,
            },
            {
            property: `og:title`,
            content: title,
            },
            {
            property: `og:description`,
            content: metaDescription,
            },
            {
            property: `og:type`,
            content: `website`,
            },
            {
            name: `twitter:card`,
            content: `summary`,
            },
            {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
            },
            {
            name: `twitter:title`,
            content: title,
            },
            {
            name: `twitter:description`,
            content: metaDescription,
            },
        ].concat(meta)}
        />
        </>
    )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
