import React from 'react';
import {Container, Col} from '@bootstrap-styled/v4';
import {AboutSectionSection,AboutLeft,
    AboutRight,AboutHeading,AboutDesc,AboutBtn,AboutRow} from './about.style'
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const AboutSection = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
            corporateJson{
                        About{
                                DelayConstant
                                AboutLeftImage{
                                        childImageSharp{
                                            fluid(quality: 100){
                                            ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                AboutHeading
                                AboutDesc
                                AboutBtn
                            }

                        }
                    }
                `);
            
    return (
        <AboutSectionSection id="aboutContainer">
            <Container>
                <AboutRow>
                    <Col md="6">
                        <Fade left delay={1*JSONData.corporateJson.About.DelayConstant}>
                            <AboutLeft>
                                <GatsImg 
                                        fluid={JSONData.corporateJson.About.AboutLeftImage.childImageSharp.fluid} 
                                        className="AboutLeftImage"
                                        alt=""
                                    />
                            </AboutLeft>
                        </Fade>
                    </Col>
                    <Col md="6">
                        <AboutRight>
                            <Fade right delay={1*JSONData.corporateJson.About.DelayConstant}>
                                <AboutHeading>{JSONData.corporateJson.About.AboutHeading}</AboutHeading>
                            </Fade>
                            <Fade right delay={2*JSONData.corporateJson.About.DelayConstant}>
                                <AboutDesc>
                                    <p>
                                        Bitten Byte, Inc. is an IT company specializing in the development and implementation of unique software products.
                                    </p>
                                    <p>
                                        For approximately 275 days a year, our team of developers and designers create large and complex projects, produce SAAS products,
                                        draw beautiful graphical objects, and develop user-friendly interfaces.
                                        In the remaining 90 days, we think about how we can improve our existing products, study new technologies and create new unique
                                        IT systems.
                                    </p>
                                    <p>
                                        Our team of experienced developers and young programmers are unique and make it possible to combine decades of experience
                                        and new, immaculate ideas, and byte by byte create software that we are proud to present.
                                    </p>
                                    {JSONData.corporateJson.About.AboutDesc}
                                </AboutDesc>
                            </Fade>
                            <Fade right delay={3*JSONData.corporateJson.About.DelayConstant}>
                                <AboutBtn href="#contactContainer">{JSONData.corporateJson.About.AboutBtn}</AboutBtn>
                            </Fade>
                        </AboutRight>
                    </Col>
                </AboutRow>
            </Container>
        </AboutSectionSection>
    )
}

export default AboutSection;